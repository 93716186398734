
<template>
  <div>
    <div class="row gy-2">
      <div class="col-12">
        <h5 class="mb-3">List of Voters Identification Numbers (VIN)</h5>
        <div class="row mb-3 gy-2 justify-content-between">
          <div class="col-md-6 col-lg-4 d-flex">
            <div class="dropdown no-arrow me-2">
              <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bx bx-menu"></i>
              </a>
              <ul class="dropdown-menu">
                <li @click.prevent="toggleFilters()">
                  <button class="dropdown-item" type="button">
                    Toggle Filters
                  </button>
                </li>
              </ul>
            </div>
            <div class="input-group w-auto">
              <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            </div>
          </div>
          <div class="col-md-5">
            <div class="input-group mb-0">
              <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search VIN here..." aria-label="Search here..." aria-describedby="button-search_TB">
              <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
            </div>
          </div>
        </div>
        <div class="overflow-hidden">
          <common-portlet headertitle="Filters"
          :visible="enableFilters" :open="true">
            <div class="loading-viewport">
              <div class="card-body p-lg-3">
                <voter-identity-filter-form 
                @startSearch="setFilters($event)"
                @resetFilters="initializeAll()" />
              </div>
            </div>
          </common-portlet>
        </div>
        <div class="mb-3">
          <p class="mb-0">Showing {{paginationInfo}} results</p>
        </div>
        <div class="card">
          <div class="card-body p-3">
            <div class="table-responsive mb-0 loading-viewport">
              <is-loading v-if="isLoading" :box="true" />
              <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm"  align="middle">
                <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
                  v-slot:[`head(${column.key})`] >
                    <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                    d-flex align-items-center">
                      {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                    </div>
                </template>
                <template v-slot:cell(vin)="data">
                  <div style="min-width:10rem;" class="cursor-pointer">
                    {{ data.item.vin }}
                  </div>
                </template>
                <template v-slot:cell(pu_code)="data">
                  <div style="min-width:7rem;" class="cursor-pointer">
                    {{ data.item.pu_code }}
                  </div>
                </template>
                <template v-slot:cell(first_name)="data">
                  <div style="min-width:7rem;" class="cursor-pointer">
                    {{ data.item.first_name }}
                  </div>
                </template>
                <template v-slot:cell(last_name)="data">
                  <div style="min-width:7rem;" class="cursor-pointer">
                    {{ data.item.last_name }}
                  </div>
                </template>
                <template v-slot:cell(gender)="data">
                  <div style="min-width:5rem;" class="cursor-pointer">
                    {{ data.item.gender }}
                  </div>
                </template>
                <template v-slot:cell(yob)="data">
                  <div style="min-width:3rem;" class="cursor-pointer">
                    {{ data.item.yob }}
                  </div>
                </template>
                <template v-slot:cell(lga)="data">
                  <div style="min-width:6rem;"
                    v-if="data.item.ward && data.item.ward.lga"  class="cursor-pointer">
                    <span class="text-dark font-sm">{{ data.item.ward.lga.name }}</span>
                  </div>
                </template> 
                <template v-slot:cell(ward)="data">
                  <div style="min-width:10rem;"
                    v-if="data.item.ward"  class="cursor-pointer">
                    <span class="text-dark font-sm">{{ data.item.ward.name }}</span>
                  </div>
                </template> 
                <template v-slot:cell(unit)="data">
                  <div style="min-width:20rem;"
                    v-if="data.item.unit"  class="cursor-pointer">
                    <span class="text-dark font-sm">{{ data.item.unit.name }}</span>
                  </div>
                </template> 
              </b-table>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import _ from 'lodash';
import IsLoading from '@/components/IsLoading.vue';
import VoterIdentityFilterForm from '@/components/forms/VoterIdentityFilterForm.vue';
import CommonPortlet from '@/components/portlets/CommonPortlet.vue';

export default {
  name: "application-list",
  components:{
    IsLoading,
    VoterIdentityFilterForm,
    CommonPortlet,
  },
  data() {
    return {
      isLoading: false,
      enableFilters: false,
      queryFilters: null,
      searchQuery:"",
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
            key: "vin",
            label: "VIN",
            sortable: true
        },
        {
            key: "pu_code",
            label: "PU Code",
            sortable: true
        },
        {
            key: "first_name",
            label: "First name",
            sortable: true
        },
        {
            key: "last_name",
            label: "Last name",
            sortable: true
        },
        {
            key: "gender",
            label: "Gender",
            sortable: true
        },
        {
            key: "yob",
            label: "YOB",
            sortable: true
        },
        {
            key: "lga",
            label: "LGA",
        },
        {
            key: "ward",
            label: "Ward",
        },
        {
            key: "unit",
            label: "Polling Unit",
        }
      ],
      sortBy: {
        sort_column: "vin",
        sort_direction: "asc"
      },
      pageData:{
        data: []
      }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 50
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
    getSortingLabel(){
      return key => {
        let iconClass = 'text-muted ri-arrow-up-line';
        if(this.sortBy.sort_column != key) return `<span class='ms-2 ${iconClass}'></span>`;
        iconClass = this.sortBy.sort_direction == 'desc' ? 'ri-arrow-up-line' : 'ri-arrow-down-line';
        return `<span class='ms-2 ${iconClass}'></span>`
      }
    }
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.sortBy
      }
      if(this.searchQuery){ payload.search = this.searchQuery }
      if(this.queryFilters) { Object.assign(payload, this.queryFilters)}
      this.isLoading = true
      this.$http.get(`/voter-identities?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
            this.initializeAll();
          }
        })
    },
    toggleFilters(){
      this.enableFilters = !this.enableFilters; 
      this.initializeAll()
    },
    setFilters(data){
      this.queryFilters = data
      this.fetchItems()
    },
    setSortOrder(column) {
      if (this.sortBy.sort_column === column.key) {
        this.sortBy.sort_direction = this.sortBy.sort_direction === 'desc' ? 'asc' : 'desc';
      } else {
        this.sortBy.sort_column = column.key;
        this.sortBy.sort_direction = 'desc';
      }
      this.fetchItems();
    },
    initializeAll(){
      this.queryFilters = null;
    },
  },
  mounted() {
    this.fetchItems()
  },
}
</script>

